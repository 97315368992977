<template>
  <div class="container-fluid px-0">
    <b-card no-body>
      <b-card-header class="d-flex flex-row align-items-center">Board Level Test File Upload</b-card-header>
      <b-card-body>
        <b-row>
          <b-col>
            <div class="text-max-width">
              Upload encrypted board level test results files here.
            </div>

            <b-form-file v-model="file" :state="file ? true : null" placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here...">

            </b-form-file>
            <div class="mt-3">Selected file: {{ file ? file.name : '' }}</div>
            <b-button variant="primary" @click="submitEncryptedData">Submit</b-button>
            <div class="mt-3">
              <b-toast id="submit-message" title="Response" static no-auto-hide>{{ response }}</b-toast>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'BLTUpload',
  data() {
    return {
      file: null,
      response: '',
    };
  },
  methods: {
    async submitEncryptedData() {
      let res;
      try {
        res = this.file ? await this.$fetch(
          'https://decrypt-eetuke6sba-pd.a.run.app/decrypt/',
          { method: 'POST', body: { data: await this.file.text() }, kauth: 'include' }
        ) : 'No file provided';
      } catch (error) {
        res = 'Request failed';
      }

      this.response = res;
      this.$bvToast.show('submit-message');
    },
  },
};
</script>

<style scoped></style>
